<template>
    <CForm ref="form" class="needs-validation"   >
        <CRow>
            <CCol>
                <CSelect
                    label="İlişik Ürün: "
                    horizontal
                    placeholder="Seçiniz.."
                    type="text"
                    :options="activeProducts"
                    :value.sync="data.productId"
                    autocomplete="text"
                />
            </CCol>
            <CCol>
                <CInput
                    label="Sıralama: "
                    horizontal
                    type="number"
                    autocomplete="text"
                    v-model="data.displayOrder"
                />    
            </CCol>
        </CRow>
   </CForm>
</template>

<script>
   export default{
       name: "ProductUpsaleForm",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create')
                   this.data = {}
           }
       },
       computed:{
        activeProducts: function(){
            let data = []
            this.$store.getters.activeProducts.map(r => data.push({value:r.id, label: r.name}))
            return data
            
        }
       },
       data: function(){
           return{
                data: this.params ? {...this.params} : {},
                attrExtFieldNames: ["Uzun ürün açıklaması...", "Product long description..."],
           }
       },
   }
</script>